@import '../../styles/propertySets.css';

.root {
  & p {
    @apply --marketplaceH4FontStyles;
    text-align: justify;
  }
  & h2 {
    /* Adjust heading margins to work with the reduced body font size */
    margin: 29px 0 23px 0;

    @media (--viewportMedium) {
      margin: 32px 0 12px 0;
    }
  }

  & h3 {
    /* Adjust heading margins to work with the reduced body font size */
    margin: 29px 0 23px 0;

    @media (--viewportMedium) {
      margin: 32px 0 12px 0;
    }
  }

  & .lastUpdated {
    @apply --marketplaceBodyFontStyles;
    margin-top: 0;
    margin-bottom: 55px;

    @media (--viewportMedium) {
      margin-top: 0;
      margin-bottom: 54px;
    }
  }
}

ol {
  padding-left: 15px;
}

table {
  width: 100%;
}

.right {
  text-align: right;
}
