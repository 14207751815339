@import '../../styles/propertySets.css';

.progressBarContainer {
  @media (--viewportLarge) {
    position: absolute;
    left: 36px;
    right: 36px;
    top: 25px;
  }

  &:global(.hideOnMobile) {
    display: none;

    @media (--viewportLarge) {
      display: block;
    }
  }

  &:global(.hideOnDesktop) {
    margin: 0 24px;
    padding: 20px 0 10px;

    @media (--viewportLarge) {
      display: none;
    }

    & .progressBarBase {
      left: 11px;
      right: auto;
      top: 11px;
      bottom: 11px;
      width: 3px;
      height: auto;
    }

    & .progressBarSteps {
      display: block;
    }

    & .progressBarStep {
      display: flex;
      padding: 10px 0;
    }

    & .progressBarStepMarker {
      margin: 0 20px 0 0;
    }

    & .progressBarStepTitle {
      align-self: center;
      margin: 0;
    }
  }
}

.progressBar {
  position: relative;
}

.progressBarBase {
  background-color: var(--matterColorNegative);
  height: 3px;
  position: absolute;
  left: calc((100% / 7) / 2);
  right: calc((100% / 7) / 2);
  top: 11px;
}

.progressBarSteps {
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
}

.progressBarStep {
  &.progressBarStepCompleted,
  &.progressBarStepCurrent {
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 11px;
      top: 0;
      bottom: 0;
      width: 3px;
      background-color: var(--successColor);
      z-index: 0;

      @media (--viewportLarge) {
        left: 0;
        right: 0;
        height: 3px;
        top: 11px;
        width: auto;
      }
    }
  }

  &.progressBarStepCompleted:first-of-type::before {
    top: 50%;
  }

  &.progressBarStepCompleted:last-of-type::before {
    bottom: 50%;
  }

  &.progressBarStepCurrent::before {
    bottom: 50%;
  }

  @media (--viewportLarge) {
    &.progressBarStepCompleted:first-of-type::before {
      top: 11px;
      left: 50%;
    }

    &.progressBarStepCompleted:last-of-type::before {
      bottom: auto;
      right: 50%;
    }

    &.progressBarStepCurrent::before {
      bottom: auto;
      right: 50%;
    }
  }
}

.progressBarStepMarker {
  background-color: var(--matterColorLight);
  border: 3px solid var(--matterColorNegative);
  border-radius: 100%;
  height: 25px;
  width: 25px;
  margin: 0 auto;
  position: relative;
}

.progressBarMarkerCompleted {
  background: url('data:image/svg+xml;utf8,<svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.745 0.167004C10.0471 0.420735 10.0862 0.871295 9.83251 1.17336L3.83252 8.3162C3.69955 8.4745 3.5045 8.56743 3.29779 8.57096C3.09108 8.57449 2.89297 8.48828 2.75467 8.33461L0.183245 5.47747C-0.0806537 5.18425 -0.0568835 4.73261 0.236337 4.46872C0.529558 4.20482 0.981193 4.22859 1.24509 4.52181L3.267 6.76838L8.73865 0.254512C8.99238 -0.0475491 9.44294 -0.0867282 9.745 0.167004Z" fill="white"/></svg>')
    no-repeat center;
  background-color: var(--successColor);
  border-color: var(--successColor);
}

.progressBarMarkerCurrent {
  border-color: var(--marketplaceColor);
}

.progressBarStepTitle {
  font-size: 13px;
  line-height: 1.3;
  text-align: center;
  margin-top: 6px;
}
