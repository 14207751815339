@import '../../styles/propertySets.css';

.root {
  margin: 0 -5px;
}

.proposalItem {
  margin-bottom: 15px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.proposal {
  border-radius: var(--borderRadiusMobileSearch);
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 5px;
  transition: var(--transitionStyle);

  &:hover {
    background-color: rgba(231, 231, 231, 0.4);
  }
}

.proposalActive {
  background-color: rgba(231, 231, 231, 0.4);
}

.proposalData {
  flex: 1 0 auto;
  line-height: 1;
}

.proposalState {
  color: var(--marketplaceColor);
  font-size: 14px;
  line-height: 1.4;
  margin: 4px 0 0;
}

.proposalStateShortlisted {
  color: var(--successColor);
  font-weight: var(--fontWeightBold);
}

.avatar {
  height: 50px;
  width: 50px;
  margin-right: 15px;
  flex-shrink: 0;
}

.proposalContent {
  @apply --marketplaceH4FontStyles;
  font-style: italic;
  margin: 0;
  white-space: pre-line;
}

.proposalInfo {
  @apply --marketplaceH5FontStyles;
  margin-top: 10px;
  margin-bottom: 0px;

  @media (--viewportMedium) {
    margin-top: 9px;
  }
}

.separator {
  margin: 0 7px;
}

.desktopSeparator {
  margin: 0 7px;
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.mobileReviewRating {
  display: block;
  margin-top: 3px;
  margin-bottom: 9px;

  @media (--viewportMedium) {
    display: none;
  }
}

.desktopReviewRatingWrapper {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  position: relative;
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.desktopReviewRating {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  display: inline-flex;
  position: absolute;
  top: 4px;
  white-space: nowrap;
}

.proposalRatingStar {
  height: 12px;
  width: 12px;
  margin-right: 2px;

  &:last-of-type {
    margin-right: 0;
  }
}
