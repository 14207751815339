@import '../../../styles/propertySets.css';

.mobileInputRoot {
  margin-bottom: 24px;
  width: 100%;
}

.desktopInputRoot {
  height: var(--topbarHeightDesktop);
}

.mobileIcon {
  margin-left: 24px;
  padding: 11px 0 17px 0;

  /* Borders */
  border-bottom-width: var(--borderRadiusMobileSearch);

  display: flex;
  width: 24px;
  align-self: stretch;
  background-color: var(--matterColorLight);
}
.mobileInput {
  flex-grow: 1;

  /* Font */
  @apply --marketplaceH1FontStyles;
  font-size: 16px;

  /* Layout */
  margin: 0;
  padding: 5px 0;
  height: auto;
  line-height: unset;

  /* Borders */
  border-bottom-width: 2px;

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }
}

.desktopIcon {
  height: var(--topbarHeightDesktop);
  border: none;
  padding-top: 3px;

  display: flex;
  width: 24px;
  align-self: stretch;
  background-color: var(--matterColorLight);
}

.desktopInput {
  flex-grow: 1;

  /* Font */
  @apply --marketplaceH4FontStyles;
  font-size: 16px;
  height: 20px;
  line-height: unset;
  border: none;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 10px;

  /* Search text gets ellipsis if it's too wide */
  text-overflow: ellipsis;
  overflow-x: hidden;

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }

  /* Placeholder text might be too wide */
  &::placeholder {
    text-overflow: ellipsis;
    overflow-x: hidden;
    font-weight: var(--fontWeightMedium);
    transition: var(--transitionStyleButton);
  }

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: var(--fontWeightSemiBold);
  }

  @media (--viewportLarge) {
    height: 100%;
    padding-left: 15px;
  }
}

.mobilePredictions {
  position: absolute;
  top: calc(var(--TopbarSearchForm_inputHeight) - var(--TopbarSearchForm_bottomBorder));
  left: 0;
  min-height: calc(
    100vh -
      calc(
        var(--TopbarSearchForm_topbarMargin) + var(--TopbarSearchForm_inputHeight) -
          var(--TopbarSearchForm_bottomBorder)
      )
  );
  width: 100%;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);
}

.mobilePredictionsAttribution {
  /* When using the Google Maps Places geocoder, the "Powered by Google"
   text is hidden in Mobile Safari without giving some extra space to
   it. */
  margin-bottom: 100px;
}

.desktopPredictions {
  position: absolute;
  top: 26px;
  left: -1px;
  right: -1px;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  margin-top: calc(
    var(--topbarHeightDesktop) - var(--TopbarSearchForm_inputHeight) +
      var(--TopbarSearchForm_bottomBorder)
  );
  max-width: 434px;

  @media (--viewportMedium) and (max-width: 1023px) {
    left: 0;
    right: 0;
  }
}

/**
 * Keyword search
 */
.keywordSearchWrapper {
  position: relative;
  display: flex;
}

.searchSubmit {
  border: 0;
  padding: 0;
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;

    & .iconSvgGroup {
      stroke: var(--matterColor);
    }
  }
}

.icon {
  display: flex;
  width: 24px;
  align-self: stretch;
  border-bottom: 2px solid var(--marketplaceColor);
  background-color: var(--matterColorLight);
}

.iconSvg {
  margin: auto;
}

.iconSvgGroup {
  stroke: var(--marketplaceColor);
  stroke-width: 2.5px;

  @media (--viewportMedium) {
    stroke-width: 2px;
  }
}

.submitButton {
  min-height: 50px;

  & svg {
    height: 20px;
    margin-right: 10px;
    stroke: var(--matterColorLight);
    transition: var(--transitionStyleButton);
  }

  &:hover,
  &:focus {
    & svg {
      stroke: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) and (max-width: 1023px) {
    flex: 0 0 50px;
    height: 50px;
    margin-left: 10px;
    min-height: 40px;
    padding: 0;
    width: auto;

    & span {
      display: none;
    }

    & svg {
      margin: 0 0 0 -1px;
    }
  }

  @media (--viewportLarge) {
    max-width: 140px;
  }
}
