@import '../../styles/propertySets.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  background-color: var(--marketplaceColorBeige);
  border: 1px solid var(--marketplaceColorBeige);
  border-radius: var(--borderRadius);
  transition: var(--transitionStyle);

  /* Remove link's hover effect */
  &:hover {
    box-shadow: var(--boxShadow);
    border-color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--matterColorNegative); /* Loading BG color */
  border-radius: 4px;

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImage {
  border: solid 1px var(--matterColorNegative);
  border-radius: 4px;
  width: 100%;
  height: 100%;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-right: 18px;
}

.priceValue {
  /* Font */
  @apply --marketplaceH3FontStyles;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);
  display: inline-flex;
  align-items: center;
  margin-left: 8px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.secondaryInfo {
  padding: 0 20px 10px;
}

.secondaryInfoLine {
  @apply --marketplaceSmallFontStyles;
  color: #888;
  line-height: 1.5;
  margin: 0;

  &::before {
    content: '‣';
    color: var(--marketplaceColor);
    margin-right: 0.3em;
    font-size: 30px;
    line-height: 14px;
    height: 18px;
    display: inline-block;
    vertical-align: middle;
  }

  & > span:first-child {
    margin-right: 0.5em;
  }

  & > span:last-child {
    color: var(--matterColor);
  }
}

.title {
  /* Font */
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  padding: 15px 20px 10px;

  @media (--viewportMedium) {
    font-size: 17px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.description {
  padding: 0 20px 20px;

  & p {
    color: var(--matterColor);
    font-size: 14px;
    margin: 0;
  }
}

.authorInfo {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}
